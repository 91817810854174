import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { ProjectPageQuery } from '../../graphql-types';
import Img, { FluidObject } from 'gatsby-image';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import './project.scss';
import { ClassHelpers } from '@rocketmakers/armstrong';
import { IconWrapper } from '../components/iconWrapper';
interface IProps {
  data: ProjectPageQuery;
}
const Project: React.FC<IProps> = (props) => {
  const params = {
    freeMode: true,
    spaceBetween: 30,
    mousewheel: true,
    slidesPerView: 'auto',
    resistance: false,
    parallax: true,
  };

  const getComponent = React.useCallback((primary: any, type: string) => {
    switch (type) {
      case 'big_image':
        return <BaseProjectImage heightRatio={1} image={primary.imageSharp.childImageSharp.fluid} className="big" />;
      case 'small_image':
        return (
          <BaseProjectImage heightRatio={0.7} image={primary.imageSharp.childImageSharp.fluid} className="small" />
        );
      case 'stacked_images':
        return (
          <div className="stacked">
            <div>
              <BaseProjectImage heightRatio={0.5} image={primary.top_imageSharp.childImageSharp.fluid} />
            </div>
            <div>
              <BaseProjectImage heightRatio={0.5} image={primary.bottom_imageSharp.childImageSharp.fluid} />
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }, []);

  return (
    <Layout
      className="project-view"
      onehundredvh={true}
      pageMeta={{
        title: `${props.data.prismic.project.name[0].text} | Evie Harbury Food Stylist`,
        image: {
          src: props.data.prismic.project.preview_image[0].default_image.url,
          height: props.data.prismic.project.preview_image[0].default_image.dimensions.height,
          width: props.data.prismic.project.preview_image[0].default_image.dimensions.width,
        },
        description: props.data.prismic.project.description[0].text,
        keywords: 'Chef, Food Stylist, Home Economist',
      }}
    >
      <Swiper {...params} containerClass="image-swiper" parallaxEl={{ el: 'para', value: '-400' }}>
        {/* <> */}
        <div className="project-info">
          <h1>{props.data.prismic.project.name[0].text}</h1>
          <p>{props.data.prismic.project.description[0].text}</p>
          <Img
            fadeIn={true}
            backgroundColor="#F6F6F6"
            className="project-image"
            fluid={props.data.prismic.project.preview_image[0].default_imageSharp.childImageSharp.fluid}
          />
          <IconWrapper icon2="rolling-pin">Scroll for more images</IconWrapper>
        </div>
        {props.data.prismic.project?.body?.map(({ primary, type }) => getComponent(primary, type))}
        {/* </> */}
      </Swiper>
    </Layout>
  );
};

const BaseProjectImage: React.FC<{ image: FluidObject; heightRatio: number; className?: string }> = ({
  heightRatio,
  image,
  className,
}) => {
  const sliderCssHeight = '100vh - 90px';

  return (
    <Img
      fadeIn={true}
      backgroundColor="#F6F6F6"
      className={ClassHelpers.classNames('project-image', className)}
      style={{
        paddingLeft: `calc(((${sliderCssHeight}) * ${heightRatio}) * ${image.aspectRatio})`,
      }}
      fluid={image}
    />
  );
};

export const pageQuery = graphql`
  query ProjectPage($uid: String!) {
    prismic {
      project(uid: $uid, lang: "en-gb") {
        name
        description
        preview_image {
          default_image
          default_imageSharp {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        body {
          ... on PRISMIC_ProjectBodyBig_image {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_ProjectBodySmall_image {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_ProjectBodyStacked_images {
            type
            primary {
              top_image
              top_imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              bottom_image
              bottom_imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
